<template>
  <dialogComp :title="newTitle" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="父级菜单:" v-if="menuLevel !== 1 && title !== 'edit'">
        <span>{{ info.name }}</span>
        <!-- <span v-for="(item,index) in info.children" :key="index">{{item.name}}</span> -->
      </el-form-item>
      <inputFormComp
        :label="`${menuLevel!==3?'菜单名称':'按钮名称'}:`"
        prop="name"
        :hval="formData.name"
        @blur="nameBlur"
        :placeholder="`请输入${menuLevel!==3?'菜单名称':'按钮名称'}:`"
      />
      <el-form-item label="级别:" prop="level">
        <el-select v-model="menuLevel" size="small" disabled placeholder="请选择级别">
          <el-option
            v-for="item in levelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <inputFormComp
        v-if="menuLevel !==3"
        label="页面地址:"
        prop="label"
        :hval="formData.label"
        @blur="labelBlur"
        placeholder="请输入页面地址"
      />
        <!-- v-if="menuLevel !==3" -->
      <inputFormComp
        inputType="number"
        label="排序:"
        prop="orderNum"
        :hval="formData.orderNum"
        @blur="orderNumBlur"
        placeholder="请输入排序"
      />
      <selectFormComp
      v-if="menuLevel !==3"
        label="是否隐藏:"
        prop="hide"
        :hval="formData.hide"
        :data="hideList"
        @change="hideChange"
      />
      <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
      <!-- <el-form-item>
        <el-radio-group v-model="formData.start">
          <el-radio :label="1">启动</el-radio>
          <el-radio :label="2">停用</el-radio>
        </el-radio-group>
      </el-form-item> -->
    </el-form>
  </dialogComp>
</template>
<script>
import { menuAdd, menuEdit } from '@/api'
export default {
  name: '',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'add'
    },
    menuLevel: {
      type: Number,
      default: 1
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data () {
    return {
      levelList: [
        { label: '一级菜单', value: 1 },
        { label: '二级菜单', value: 2 },
        { label: '按钮', value: 3 }
      ],
      hideList: [
        { label: '是', value: true },
        { label: '否', value: false }
      ],
      formData: {
        name: '',
        label: '',
        remark: '',
        orderNum: null,
        level: null,
        hide: false
      },
      rules: {
        name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        label: [{ required: true, message: '请输入页面地址', trigger: 'blur' }],
        orderNum: [{ required: true, message: '请输入排序', trigger: 'blur' }]
      }
    }
  },
  computed: {
    newTitle () {
      if (this.menuLevel === 3) {
        return this.title === 'add' ? '增加按钮' : '编辑按钮'
      } else {
        return this.title === 'add' ? '增加菜单' : '编辑菜单'
      }
    }
  },
  watch: {
    isShow (val) {
      this.formData = { name: '', label: '', remark: '', orderNum: null, level: null }
      this.formData.level = this.menuLevel
      this.formData.hide = false
      if (val && this.title === 'edit') {
        const { name, level, label, orderNum, remark, hide } = this.info
        this.formData.name = name
        this.formData.label = label
        this.formData.level = level
        this.formData.remark = remark
        this.formData.orderNum = orderNum
        this.formData.hide = hide
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    nameBlur (val) {
      this.formData.name = val
    },
    labelBlur (val) {
      this.formData.label = val
    },
    levelChange (val) {
      this.formData.level = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    orderNumBlur (val) {
      this.formData.orderNum = val
    },
    hideChange (val) {
      this.formData.hide = val
    },
    close () {
      this.$refs.formData && this.$refs.formData.resetFields()
      this.formData.hide = false
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.title === 'add') {
            const params = {
              parentCode: this.menuLevel !== 1 ? this.info.code : null,
              type: this.menuLevel === 3 ? 2 : 1,
              ...this.formData
            }
            menuAdd(params).then((res) => {
              if (res.code === '1') {
                this.$message.success('新增成功')
                this.close()
              }
            })
          } else {
            menuEdit({ ...this.formData, id: this.info.id }).then((res) => {
              if (res.code === '1') {
                this.$message.success('编辑成功')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
