<template>
  <div class="layout_common menu_manage">
    <div class="main_box">
      <div class="main_box_con">
        <btnComp class="btn" @click="add(1, 'add')" v-permission="'添加一级菜单'">添加一级菜单</btnComp>
        <el-table
          v-loading="loading"
          class="table"
          :data="tableData"
          :header-cell-style="{
            color: 'rgba(0,0,0,0.85)',
            background: '#FAFAFA',
          }"
          row-key="id"
          border
          :default-expand-all="false"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="name" label="菜单名称" width="220">
          </el-table-column>
          <el-table-column label="级别">
            <template v-slot="scope">
              {{
                scope.row.level === 1
                  ? "一级菜单"
                  : scope.row.level === 2
                  ? "二级菜单"
                  : "按钮"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="label" label="页面地址" :formatter="(row, column, cellValue) => cellValue || '-'"></el-table-column>
          <el-table-column prop="remark" label="备注" :formatter="(row, column, cellValue) => cellValue || '-'"> </el-table-column>
          <el-table-column prop="orderNum" label="排序"> </el-table-column>
          <el-table-column label="操作" width="230" fixed="right">
            <template v-slot="scope">
              <btnComp
                btnType="text"
                v-if="scope.row.level === 1"
                @click="add(2, 'add', scope.row)"
                v-permission="'添加子菜单'"
                >添加子菜单</btnComp
              >
              <btnComp
                btnType="text"
                v-if="scope.row.level !== 3"
                @click="add(3, 'add', scope.row)"
                v-permission="'添加按钮'"
                >添加按钮</btnComp
              >
              <btnComp v-if="scope.row.level === 1" btnType="text" @click="add(2, 'edit', scope.row)" v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp v-if="scope.row.level === 2" btnType="text" @click="add(2, 'edit', scope.row)" v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp v-if="scope.row.level === 3" btnType="text" @click="add(3, 'edit', scope.row)" v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp btnType="text" @click="del(scope.row.id)" v-permission="'删除'">删除</btnComp>
            </template>
          </el-table-column>
        </el-table>
        <add
          :isShow="addShow"
          @close="close"
          :title="title"
          :menuLevel="menuLevel"
          :info="info"
        />
        <dialogComp
          title="提示"
          :isShow="hintShow"
          @close="close"
          @sure="sureDel"
        >
          <p>确定删除吗？</p>
        </dialogComp>
      </div>
    </div>
  </div>
</template>
<script>
import add from './add'
import { menuList, menuDel } from '@/api'
export default {
  name: '',
  props: {},
  components: { add },
  data () {
    return {
      loading: false,
      addShow: false,
      hintShow: false,
      title: '',
      menuLevel: null,
      info: {},
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      menuList().then((res) => {
        this.loading = false
        if (res.code === '1') {
          this.tableData = res.data
        }
      })
    },
    add (level, type, row) {
      this.menuLevel = level
      this.title = type
      if (level !== 1) {
        this.info = row
      }
      this.addShow = true
    },
    close () {
      this.addShow = false
      this.hintShow = false
      this.getList()
    },
    del (id) {
      this.id = id
      this.hintShow = true
    },
    sureDel () {
      menuDel({ id: this.id }).then((res) => {
        if (res.code === '1') {
          this.$message.success('删除成功')
          this.close()
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.menu_manage {
  .main_box_con
  .table {
    margin-top: 20px;
  }
}
</style>
